<template>
    <footer>

    </footer>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    footer {
        height: 150px;
        background-color: #151B2B;
    }
</style>